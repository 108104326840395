@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* font-family: 'Quicksand', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: small;
  font-weight: 600;
}

.body-container {
  margin: 0.5rem;
}

.header {
  padding: 2rem 1rem;
  justify-content: center;
}

.header h2 {
  color: black;
  margin: 0;
  text-align: center;
  font-weight: 400;
}

.header p {
  text-align: center;
}

.header img {
  background-color: white;
  height: 5rem;
  width: 15rem;
}

.content-container {
  padding: 0 1rem;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(232, 232, 237);
}

.box-thisisacopy,
.box-price {
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.15);
  /* border-radius: 0.5rem; */
  border: 1px solid rgb(219, 219, 219);
  background-color: white;
  padding: 0.75rem;
  width: 100%;
  margin: 1.25rem 0rem;
}


.box-thisisacopy h1 {
  color: red;
  text-align: center;
  margin: 0;
}

.box-experience {
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.15);
  /* border-radius: 0.5rem; */
  border: 1px solid rgb(219, 219, 219);
  background-color: white;
  /* padding: 0.75rem; */
  width: 100%;
  margin: 1.25rem 0rem;
}

.box-experience h4 {
  background-color: #3387e1;
  color: white;
  text-align: center;
  font-weight: 400;
  padding: 0.5rem;
  margin: 0;
}

.box-experience i {
  font-size: 5rem ;
}

.experience__emoji-container {
  display: flex;
  justify-content: space-evenly;
}

.vertical-line {
  border-left: 1px solid lightgray;
}

.box-price h2 {
  text-align: center;
  font-weight: 400;
  margin: 0;
}

hr {
  border-top: 2px solid rgba(0, 0, 0, 1);
  margin: 0.75em 0;
}

.box-receipt {
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.15);
  /* border-radius: 0.5rem; */
  border: 1px solid rgb(219, 219, 219);
  background-color: white;
  padding: 0.75rem 0.75rem 1.5rem ;
  width: 100%;
  margin: 1.25rem 0 0 0;
}

.receipt-info__text,
.receipt-payout__item,
.receipt-payout__subtotal,
.receipt-payout__total,
.receipt-payout__method {
  display: flex;
  justify-content: space-between !important;
}

.receipt-info p,
.receipt-payout__method p {
  color: rgb(159, 159, 159);
  margin-bottom: 0.4rem;
}

.receipt-payout_header p {
  text-align: center;
  margin-bottom: 0;
}

.receipt-payout_content p {
  color: black;
  margin-bottom: 0.4rem;
}

.receipt-payout__item-name {
  text-wrap: wrap;
}

.receipt-payout__item-price {
  text-wrap: nowrap;
  padding-left: 0.25rem;
}

.receipt-payout__subtotal p {
  color: black;
  margin-bottom: 0;
}

.receipt-payout__total p {
  color: black;
  margin-bottom: 0.4rem;
  font-size: large;
  font-weight: 600;
}

.box-map {
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.15);
  /* border-radius: 0.5rem; */
  border: 1px solid rgb(219, 219, 219);
  background-color: white;
  padding: 0rem;
  flex: 1;
  margin: 0 0 1.25rem 0;
}

.map-container {
  height: 200px;
  width: 100%;
}

.App {
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
